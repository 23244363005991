export const navigationMenu = [
  { navigationName: "Dashboard", routeUrl: "/" },
  { navigationName: "PAMM Dashboard", routeUrl: "/pamm-dashboard" },
  // { navigationName: "News", routeUrl: "/posts" },
  // { navigationName: "Calender", routeUrl: "/calender" },
  { navigationName: "Education", routeUrl: "/education" },
  { navigationName: "Video Academy", routeUrl: "/video-academy" },
  { navigationName: "Crypto Calendar", routeUrl: "/cryptocurrency-calender" },
  { navigationName: "Economic Calendar", routeUrl: "/economic-calendar" },
  { navigationName: "Financial News", routeUrl: "/financial-news" },
  { navigationName: "Glossary", routeUrl: "/glossary" },
  { navigationName: "Broker Portal", routeUrl: "/broker-portal" },
  { navigationName: "IB Dashboard", routeUrl: "/ib-dashboard" },
];