import { Search } from "@mui/icons-material";
import { FormControl, InputAdornment, TextField, useTheme } from "@mui/material";
import { memo } from "react";

const SearchByEmail = memo(({ handleSearchEmailChange, email }) => {
  const theme = useTheme();
  return (
    <FormControl
      fullWidth
      sx={{
        padding: "5px",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid var(--color-green-7)",
          },
          "&:hover fieldset": {
            border: "1px solid var(--color-green-1)",
          },
          "&.Mui-focused fieldset": {
            border: `1px solid var(--color-green-2)`,
          },
        },
        "& .MuiInputLabel-root": {
          color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
          "&.Mui-focused": {
            color: theme.palette.mode === "dark" ? "var(--color-white-1)" : "var(--color-text-2)",
          },
        },
      }}
    >
      <TextField
        placeholder="Search by email..."
        size="small"
        value={email}
        onChange={handleSearchEmailChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search
                sx={{
                  color: "var(--text-color)",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
});

export default SearchByEmail;
