import { useTheme } from "@emotion/react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import ChatBot from "components/Chat/ChatBot";
import ChatButton from "components/Chat/ChatButton";
import LeftSidebar from "components/Sidebar/LeftSidebar";
import MiniSidebar from "components/Sidebar/MiniSidebar";
import MobileSidebar from "components/Sidebar/MobileSidebar";
import MiniTopBar from "components/Topbar/MiniTopBar";
import TopBar from "components/Topbar/TopBar";
import Loader from "components/loader/Loader";
import InitialComponent from "initialLoad/InitialComponent";
import LoginRefetch from "initialLoad/LoginRefetch";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearAnchorElChat, setAnchorElChat } from "redux/features/chatFullScreen/chatFullScreenSlice";
import { setDrawer } from "redux/features/drawer/drawerSlice";

export const drawerWidth = 240;
export const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#061F16",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    background: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    background: "var(--color-green-7)",
  },
});
export const closedMixin = (theme) => ({
  backgroundColor: "#061F16",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-track": {
    background: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "8px",
    background: "var(--color-green-7)",
  },
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: "var(--color-black-2)",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout = ({ children }) => {
  const location = useLocation();
  const isSupportTicketRoute = location.pathname === "/support-ticket";
  const globalLoading = useSelector((state) => state.application.globalLoading);
  const isFullScreenChatRoute = location.pathname === "/chat";
  const permission = useSelector((state) => state.permission.permission);
  const anchorElChat = useSelector((state) => state.chatFullScreen.anchorElChat);
  const dispatch = useDispatch();
  const theme = useTheme();
  const open = useSelector((state) => state.drawer.open);
  const handleDrawerOpen = () => {
    dispatch(setDrawer());
  };
  const handleDrawerClose = () => {
    dispatch(setDrawer());
  };

  const openChat = Boolean(anchorElChat);
  const handlePopoverOpenChat = (event) => {
    dispatch(setAnchorElChat(event.currentTarget));
  };
  const handlePopoverCloseChat = () => {
    dispatch(clearAnchorElChat());
  };

  return (
    <>
      <InitialComponent />
      {globalLoading ? (
        <Loader />
      ) : (
        <>
          <LoginRefetch />
          <Box sx={{ display: "flex", overflowX: "hidden", height: "100vh" }}>
            <CssBaseline />
            <Box container="true" display={{ xs: "none", sm: "block" }}>
              <LeftSidebar {...{ open, handleDrawerClose }} />
            </Box>
            <Box container="true" display={{ sm: "none", xs: "none" }}>
              <MiniSidebar {...{ open, handleDrawerClose }} />
            </Box>
            <Box
              container="true"
              display={{ xs: "none", sm: "block" }}
              sx={{
                width: "100%",
                height: "100vh",
                flexDirection: "column",
              }}
            >
              <TopBar {...{ open, handleDrawerOpen }} />
              <Box
                component="main"
                className="mirror-trade-global-scrollbar"
                sx={{
                  flexGrow: 1,
                  width: "100%",
                  height: "100vh",
                  background: theme.palette.primary.main,
                  overflowY: "auto",
                }}
              >
                <DrawerHeader />
                {children}
              </Box>
            </Box>

            {!open ? (
              <MobileSidebar {...{ open, handleDrawerClose }} />
            ) : (
              <Box
                container="true"
                display={{ xs: "block", sm: "none" }}
                sx={{
                  width: "100%",
                  height: "100vh",
                  flexDirection: "column",
                }}
              >
                <MiniTopBar {...{ open, handleDrawerOpen }} />
                <Box
                  component="main"
                  className="mirror-trade-global-scrollbar"
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    height: "100vh",
                    background: theme.palette.primary.main,
                    overflowY: "auto",
                  }}
                >
                  <DrawerHeader />
                  {children}
                </Box>
              </Box>
            )}
          </Box>
          {permission?.includes("chatting") && !isSupportTicketRoute && !isFullScreenChatRoute && <ChatButton {...{ handlePopoverOpenChat }} />}
          {openChat && <ChatBot {...{ openChat, anchorElChat, handlePopoverCloseChat }} />}
        </>
      )}
    </>
  );
};
export default memo(Layout);
