import { Menu, MenuItem, useTheme } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";

const MultipleAccountDropdown = memo(({ anchorElMultipleAccount, multipleAccountOpen, handleCloseMultipleAccount, handleSelectedAccount }) => {
  const theme = useTheme();
  const accounts = useSelector((state) => state.auth.accounts);
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorElMultipleAccount}
      open={multipleAccountOpen}
      onClose={handleCloseMultipleAccount}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        "& .MuiPaper-root": {
          background: theme.palette.mode === "dark" ? "var(--color-green-8)" : "var(--color-green-10)",
        },
      }}
      slotProps={{
        paper: {
          className: "chat-list-scrollbar",
          style: {
            maxHeight: "250px",
          },
        },
      }}
    >
      {accounts?.map((item, i) => (
        <MenuItem
          key={i}
          onClick={() => {
            handleCloseMultipleAccount();
            handleSelectedAccount(item);
          }}
        >
          {item?.account_number}
        </MenuItem>
      ))}
    </Menu>
  );
});

export default MultipleAccountDropdown;
