import { useTheme } from "@emotion/react";
import { Box, Button, Typography } from "@mui/material";
import { Fragment, memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCopyTradeModalOpen, setOpenUnCopyDialog } from "redux/features/application/applicationSlice";
import { setBalanceEquity } from "redux/features/pam/pamSlice";
import { getBalanceEquity } from "server/pamm";

const Account = () => {
  const permission = useSelector((state) => state.permission.permission);
  const balanceEquity = useSelector((state) => state.pam.balanceEquity);
  const pamUser = useSelector((state) => state.pam.pamUserDetails);
  const { id } = useParams();
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const dispatch = useDispatch();

  const copyHandler = async () => {
    dispatch(setCopyTradeModalOpen(true));
  };
  const unCopyHandler = async () => {
    dispatch(setOpenUnCopyDialog(true));
  };

  const fetchBalanceEquity = async () => {
    const result = await getBalanceEquity(id);
    if (result && result?.data?.Success) {
      dispatch(setBalanceEquity(result?.data?.Data));
    }
  };

  useEffect(() => {
    fetchBalanceEquity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Typography className="pb-[5px] dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !font-[Poppins] !font-bold !text-[16px] !leading-normal">
        Account Details
      </Typography>
      <Box
        sx={{
          boxShadow: "0px 3px 18.1px 0px rgba(0, 208, 148, 0.10)",
          border: "1px solid rgba(0, 208, 148, 0.3)",
          background:
            theme.palette.mode === "dark"
              ? "linear-gradient(180deg, rgba(42, 71, 63, 0.05) 2.1%, rgba(26, 41, 37, 0.47) 57.45%, rgba(26, 41, 37, 0.16) 98.26%)"
              : "var(--color-green-10)",
          borderRadius: "10px",
        }}
        className="!p-[10px] h-[398px] space-y-4"
      >
        <Box>
          <Typography noWrap className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !font-[Open Sans] !text-[18px] !font-normal !capitalize !leading-normal">
            {pamUser?.name}
          </Typography>
        </Box>
        <Box>
          <Typography className="dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !font-[Poppins] !text-[12px] !font-normal !leading-[18px]">Growth</Typography>
          <Typography className="!text-[var(--color-green-1)] !font-[Anek Telugu] !text-[18px] !font-normal !leading-normal">
            {/* {!isNaN(pamUser?.growth_percent) && Math.round(pamUser?.growth_percent)}% */}
            {pamUser?.growth_percent}%
          </Typography>
        </Box>
        <Box>
          <Typography className="dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !font-[Poppins] !text-[12px] !font-normal !leading-[18px]">Profit/loss</Typography>
          <Typography className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !font-[Anek Telugu] !text-[18px] !font-normal !leading-normal">
            {pamUser?.profit?.toFixed(2)} USD
          </Typography>
        </Box>
        <Box>
          <Typography className="dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !font-[Poppins] !text-[12px] !font-normal !leading-[18px]">Equity Percentage</Typography>
          <Typography className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !font-[Anek Telugu] !text-[18px] !font-normal !leading-normal">
            {/* {pamUser?.account?.equity > 0 && pamUser?.account?.balance ? (parseFloat(pamUser?.account?.equity) / parseFloat(pamUser?.account?.balance)) * 100 : 0}% */}
            {balanceEquity?.Equity > 0 && balanceEquity?.Balance ? ((parseFloat(balanceEquity?.Equity) / parseFloat(balanceEquity?.Balance)) * 100)?.toFixed(2) : 0}%
          </Typography>
        </Box>
        <Box>
          <Typography className="dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !font-[Poppins] !text-[12px] !font-normal !leading-[18px]">Balance</Typography>
          <Typography className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !font-[Anek Telugu] !text-[18px] !font-normal !leading-normal">
            {balanceEquity?.Balance ? balanceEquity?.Balance?.toFixed(2) : 0} USD
          </Typography>
        </Box>
        <Box>
          <Typography className="dark:!text-[--color-gray-1] !text-[var(--color-black-3)] !font-[Poppins] !text-[12px] !font-normal !leading-[18px]">Equity</Typography>
          <Typography className="dark:!text-[var(--color-white-1)] text-[var(--color-black-2)] !font-[Anek Telugu] !text-[18px] !font-normal !leading-normal">
            {balanceEquity?.Equity ? balanceEquity?.Equity?.toFixed(2) : 0} USD
          </Typography>
        </Box>
        <Box className="flex justify-between items-center">
          {user?.role !== "pamm" &&
            (user?.slave_of.some((i) => i.master_id === id) ? (
              <>
                {permission?.includes("uncopy") && (
                  <Button
                    fullWidth
                    onClick={unCopyHandler}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      background: "var(--color-green-1)",
                      boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                      "&:hover": {
                        color: "var(--color-white-1)",
                        background: "var(--color-green-2)",
                      },
                    }}
                  >
                    Uncopy
                  </Button>
                )}
              </>
            ) : (
              <>
                {permission?.includes("copy") && (
                  <Button
                    fullWidth
                    onClick={copyHandler}
                    variant="outlined"
                    sx={{
                      fontSize: "12px",
                      textTransform: "capitalize",
                      background: "var(--color-green-1)",
                      boxShadow: "0px 13.98px 32.62px 0px rgba(0, 208, 148, 0.10)",
                      "&:hover": {
                        color: "var(--color-white-1)",
                        background: "var(--color-green-2)",
                      },
                    }}
                  >
                    Start Copy
                  </Button>
                )}
              </>
            ))}
        </Box>
      </Box>
    </Fragment>
  );
};

export default memo(Account);
